import { Component, OnInit, HostListener } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  userDetail : any;

  constructor(private appConfigService: AppConfigService,) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
    this.appConfigService.userDetails$.subscribe(
      (res) => (this.userDetail = res)
      
    );
  }

  @HostListener('window:resize', ['$event'])
  isMobile(event:any) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit(): void {}
}
