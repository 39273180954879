import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './services/guard/auth.guard';
import { BuyerGuard } from './services/guard/buyer.guard';
import { StepperGuard } from './services/guard/stepper.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'content',
        loadChildren: () => import(`./pages/my-buying/my-buying.module`).then(m => m.MyBuyingModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'products',
        loadChildren: () => import(`./pages/products/products.module`).then(m => m.ProductsModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import(`./pages/settings/settings.module`).then(m => m.SettingsModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'suppliers',
        loadChildren: () => import(`./pages/suppliers/suppliers.module`).then(m => m.SuppliersModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'stocks',
        loadChildren: () => import('./pages/stocks/stocks.module').then(m => m.StocksModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'auto-updates',
        loadChildren: () => import('./pages/auto-updates/auto-updates.module').then(m => m.AutoUpdatesModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'activity-log',
        loadChildren: () => import('./pages/activity-logs/activity-logs.module').then(m => m.ActivityLogsModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'productview',
        loadChildren: () => import('./pages/productview/productview.module').then(m => m.ProductviewModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'stepper',
        loadChildren: () => import('./pages/stepper/stepper.module').then(m => m.StepperModule),
        canActivate:[StepperGuard]
      },
      { path: 'collection',
       loadChildren: () => import('./pages/collection/collection.module').then(m => m.CollectionModule),
       canActivate:[BuyerGuard]
      },
      { path: 'tracking',
        loadChildren: () => import('./pages/tracking/tracking.module').then(m => m.TrackingModule),
        canActivate:[BuyerGuard]
      },
      { path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule),
        canActivate:[BuyerGuard]
      },
      {
        path : 'newproducts',
        loadChildren: () => import('./pages/new-products/new-products.module').then(m => m.NewProductsModule),
        canActivate: [BuyerGuard]
      },
      {
        path: 'sizing',
        loadChildren: () => import('./pages/sizing/sizing.module').then(m => m.SizingModule),
        canActivate:[BuyerGuard]
      }
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import(`./layouts/auth-layout/auth-layout.module`).then(m => m.AuthLayoutModule),
      },
    ],
  },



];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
