import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StepperGuard implements CanActivate {
  userDetail: any;
  data: any;
  constructor(private router:Router,
    private appConfigService : AppConfigService){
    this.appConfigService.userDetails$.subscribe(
      (userDetail) => (this.userDetail = userDetail)
    );
  }
  async canActivate() {
    await this.router.events.subscribe((res: any) => {
      
      if(res instanceof NavigationEnd){
        this.data = res.url.split('/')[1]
        try{
          if(this.data == 'stepper'){
            if(this.userDetail.is_stepper_complete == 1){
              this.router.navigateByUrl('/products');
              return false;
            }
          }else return true
          
        }catch(err){
          return true
        }
      }
    });
    return true;
  }
  
  
}
