import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Router } from '@angular/router';
import { getCurrentUserData } from 'src/app/shared/current-user';
import { SidebarService } from 'src/app/services/sidebar.service';
import { askForLogoutConfirmation } from 'src/app/shared/sweet-alert-msg';

const misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  icontype?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}

export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[] = [];
  public isCollapsed = true;
  userDetails: any = null;
  loggedUser = getCurrentUserData();
  public ToolsItems: any[] = [];
  showBox = false;

  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private cdr: ChangeDetectorRef,
    private sidebarService: SidebarService
  ) {

    this.appConfigService.userDetails$.subscribe((result: any) => {
      if (result) {
        this.userDetails = result;
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 300);
      }
    });
  }

  ngOnInit(): void {
    this.showBox =
      localStorage.getItem('ToolsDropDown') == 'true' ? !true : !false;
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.ToolsItems = ROUTES.filter((toolItem) => toolItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });


    this.prepareMenuAccordingToBusinessType();

    if(window.screen.availWidth <= 1440 && window.screen.availWidth >= 768){
      localStorage.setItem('sidebar_minimize','true')
    }
    misc.sidebar_mini_active = JSON.parse(
      localStorage.getItem('sidebar_minimize') || '{}'
    );
    if (misc.sidebar_mini_active === true) {
      this.minimizeSidebar();
    }

    this.sidebarService.sideBar$.subscribe((res) => {
      misc.sidebar_mini_active = res;
      this.minimizeSidebar();
    });
  }

  closeSidebar() {
    if(window.screen.availWidth <= 1200){
      this.minimizeSidebar()
    }
  }

  prepareMenuAccordingToBusinessType(): void {
    const myBuying = {
      path: '/content',
      title: 'Content',
      type: 'link',
      icontype: 'fas fa-camera-retro sidemenu-text-color',
    };
    const products = {
      path: '/products',
      title: 'Products',
      type: 'link',
      icontype: 'fas fa-dolly sidemenu-text-color',
    };
    const newproducts = {
      path: '/newproducts',
      title: 'New Products',
      type: 'link',
      icontype: 'fas fa-dolly sidemenu-text-color',
    };
    const sizing = {
      path:'/sizing',
      title:'Sizing',
      type:'link',
      icontype:'fa fa-solid fa-ruler-horizontal sidemenu-text-color'
    }
    const supplier = {
      path: '/suppliers',
      title: 'Suppliers',
      type: 'link',
      icontype: 'far fa-address-book sidemenu-text-color',
    };
    const customers = {
      path: '/customers',
      title: 'Customers',
      type: 'link',
      icontype: 'fa fa-users sidemenu-text-color',
    };
    const brands = {
      path: '/brands',
      title: 'Brands',
      type: 'link',
      icontype: 'ni-box-2 sidemenu-text-color',
    };
    const buyers = {
      path: '/buyers',
      title: 'Buyers',
      type: 'link',
      icontype: 'ni-paper-diploma sidemenu-text-color',
    };

    const tools = {
      path: '',
      title: 'Tools',
      type: 'sub',
      subMenu: true,
      icontype: 'fas fa-wrench sidemenu-text-color',
      collapse: 'tools',
      isCollapsed: true,
      children: [
        {
          path: '/stocks',
          title: 'Back In Stock',
          type: 'link',
          icontype: 'fas fa-layer-group sidemenu-text-color',
        },
        {
          path: '/auto-updates',
          title: 'Auto Update',
          type: 'link',
          icontype: 'fas fa-sync sidemenu-text-color',
        },
        {
          path: '/productview',
          title: 'Product Views',
          type: 'link',
          icontype: 'ni ni-basket sidemenu-text-color',
        },
        {
          path:'/collection',
          title: 'Smart Collections',
          type : 'link',
          icontype :'ni ni-tag sidemenu-text-color',
        }
      ],
    };

    const activitLog = {
      path: '/activity-log',
      title: 'Activity Log',
      type: 'link',
      icontype: 'ni ni-bullet-list-67 sidemenu-text-color',
    };

    const settings = {
      path: '/settings',
      title: 'Settings',
      type: 'link',
      icontype: 'fa fas fa-cog sidemenu-text-color',
    };

    const orders = {
      path: '/orders',
      title: 'Orders',
      type: 'link',
      icontype: 'fa fa-shopping-cart sidemenu-text-color',
    };

    const tracking = {
      path:'/tracking',
      title:'Tracking',
      type:'link',
      icontype:'ni ni-pin-3 sidemenu-text-color'
    };

    if (this.loggedUser) {
      if (this.loggedUser.business_type_id === 2) {
        this.menuItems.push(brands);
        this.menuItems.push(buyers);
      } else {
        if (this.loggedUser.permissions.view_images_module == true) {
          this.menuItems.push(myBuying);
        }
        if (this.loggedUser.permissions.view_products_module == true) {
          this.menuItems.push(products);
        }
        if (this.loggedUser.permissions.view_products_module == true) {
          this.menuItems.push(newproducts);
        }
        if (this.loggedUser.permissions.view_products_module == true) {
          this.menuItems.push(sizing);
        }
        if (this.loggedUser.permissions.view_supplier_module == true) {
          this.menuItems.push(supplier);
        }
        if (this.loggedUser.permissions.view_products_module == true) {
          this.menuItems.push(customers);
        }
        if (this.loggedUser.permissions.view_tools_module == true) {
          if (!this.userDetails?.plan_limitations?.back_in_stock) {
            tools.children = tools.children.slice(1);
          }
          this.menuItems.push(tools);
        }
        if (this.loggedUser.permissions.view_setting_module == true) {
          this.menuItems.push(activitLog);
        }
        if (this.loggedUser.permissions.view_order_module == true) {
          this.menuItems.push(orders);
        }
        if (this.loggedUser.permissions.view_setting_module == true) {
          this.menuItems.push(tracking);
        }
        if (this.loggedUser.permissions.view_setting_module == true) {
          this.menuItems.push(settings);
        }

      }
    }
  }

  minimizeSidebar() {
    const sidenavToggler =
      document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];

    const nav = document.getElementsByTagName('nav')[0];
    misc.sidebar_mini_active = body.classList.contains('g-sidenav-pinned');
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      misc.sidebar_mini_active = false;
      // nav.classList.toggle('max-w-62px');
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      misc.sidebar_mini_active = true;
      nav.classList.remove('max-w-62px');
    }
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }

  async onLogout() {
    this.showBox = true;
    const result = await askForLogoutConfirmation('Logout');
    if (result.value) {
      const sidebarMinimize = localStorage.getItem('sidebar_minimize');
      localStorage.clear();
      window.location.reload();
      localStorage.setItem('sidebar_minimize', sidebarMinimize || '');
    }
  }

  preventClose(value: any) {
    localStorage.setItem('ToolsDropDown', value);
  }

  avoidClose(value: any) {
    this.showBox = true;
    localStorage.setItem('ToolsDropDown', 'false');
  }

  ngOnDestroy() {
    if (this.sidebarService.sideBar$) {
      this.sidebarService.sideBar$.unsubscribe();
    }
  }
}
