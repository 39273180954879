import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  shopify_domin: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((response) => {
      this.shopify_domin = response?.shopify_store;
    });
  }

  async canActivate() {
    try {
      const data = localStorage.getItem(environment.authTokenKey);
      if (data !== null) {
        if (this.shopify_domin != undefined || '')
          window.parent.location.href = 'https://app.bpx22.buyerpix.com/';
        else return true;
      }
    } catch (err) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}
