<div class="auth-layout">
    <router-outlet></router-outlet>
    <!-- <footer class="auth-layout__footer">
        <div class="container">
            <div class="row align-items-center justify-content-xl-between">
                <div class="col-xl-6">
                    <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{ test | date: "yyyy" }}
                        <a class="ml-1 text-white opacity-7" href="https://www.buyerpix.com/"
                            target="_blank">Buyerpix.com</a>
                    </div>
                </div>
                <div class="col-xl-6">
                </div>
            </div>
        </div>
    </footer> -->
</div>