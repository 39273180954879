<nav (mouseout)="onMouseLeaveSidenav()" (mouseover)="onMouseEnterSidenav()"
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
    <perfect-scrollbar>
        <div class="scrollbar-inner">
            <div class="sidenav-header d-flex">
                <div class="navbar-brand pt-2">
                    <div class="d-flex-v-center">
                        <div class="min-w-40px">
                            <img alt="..." class="navbar-brand-img" src="assets/images/shop-e-tell.png" />
                        </div>
                        <div class="navbar-brand-text-box pl-2" style="text-align: left">
                            <div
                                [ngClass]="{'font-large':(loggedUser?.entity_name.length) > 20 ,'font-small':(loggedUser?.entity_name.length) <= 20}">
                                {{loggedUser?.entity_name}}</div>
                            <div class="font-12px">{{userDetails?.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="ml-auto mr-2">
                    <div (click)="minimizeSidebar()" class="sidenav-toggler d-xl-block p-2 " data-action="sidenav-unpin"
                        data-target="#sidenav-main">
                        <div class="sidenav-toggler-inner d-none">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar-inner" style="height:80vh">
                <div class="collapse navbar-collapse" id="sidenav-collapse-main" disable="true">
                    <!-- Collapse header -->
                    <ul class="navbar-nav">
                        <li *ngFor="let menuitem of menuItems" class="nav-item" (click)="menuitem?.subMenu ? '' :  closeSidebar()">
                            <!--If is a single link-->
                            <a routerLinkActive="active" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'"
                                class="nav-link"
                                (click)="avoidClose(showBox)">
                                <i class="ni {{ menuitem.icontype }}"></i>
                                <span class="nav-link-text">{{ menuitem.title }}</span>
                            </a>
                            <!--If it have a submenu-->
                            <a data-toggle="collapse" routerLinkActive="active" *ngIf="menuitem.type === 'sub'"
                                (click)="showBox = !showBox; preventClose(!showBox)" [attr.aria-expanded]="!showBox"
                                [attr.aria-controls]="showBox"
                                class="nav-link tool-dropDown">
                                <i class="ni {{ menuitem.icontype }}"></i>
                                <span class="nav-link-text">{{ menuitem.title }}</span>
                            </a>
                            <!--Display the submenu items-->
                            <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type == 'sub'"
                                [collapse]="showBox" [isAnimated]="true">
                                <ul class="nav nav-sm flex-column">
                                    <li *ngFor="let childitems of menuitem.children" class="nav-item" (click)="closeSidebar()">
                                        <a routerLinkActive="active" [routerLink]="[childitems.path]"
                                            class="nav-link"
                                            *ngIf="childitems.type === 'link'">
                                            <i class="ni {{ childitems.icontype }}"></i>
                                            <span class="nav-link-text">{{ childitems.title }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" (click)="onLogout()"
                                class="nav-link">
                                <i class="fas fa-walking sidemenu-text-color"></i> <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</nav>
