import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { AppConfigService } from './services/app-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loader: any;
  constructor(
    private router: Router,
    private appConfigService: AppConfigService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        setTimeout(() => {
          this.appConfigService.lodader.next(false);
        },300)
      }

      if (event instanceof NavigationError) {
        if(localStorage.getItem(environment.authTokenKey) !== null) {
          this.router.navigate(['products'])
        } 
        // Hide loading indicator
        // Present error to user
      }
    });
    this.appConfigService.lodader.subscribe((res) => {
      this.loader = res;
    });
  }
}
