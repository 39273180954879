export const EndPoints = {
    REGISTER_API_ENDPOINT: '/signup',
    GET_FOLDER_LIST: 'get-folder-list',
    GET_FOLDERS: 'get-folders',
    LOGIN_API_ENDPOINT: '/login',
    CREATE_ITEM: 'shopify/item',
    CREATE_FOLDER: 'shopify/create-folder',
    ADD_ITEMS_TO_FOLDER: 'shopify/item/move-item',
    DELETE: 'shopify/item/delete',
    MULTIPLE_ITEM_DELETE: 'shopify/item/delete-items',
    MULTIPLE_ITEM_DELETE_FOLDER: 'shopify/item/delete-folder',
    ROTATE_IMAGE: 'shopify/item/rotate-image',
    SAVE_CROPPED_IMAGE: 'shopify/item/crop-image',
    DUPLICATE_ITEM: 'shopify/item/duplicate-item',
    EXPORT_ITEM: 'shopify/item/export-items',
    HIDE_FOLDER: 'shopify/item/hide-folder',
    HIDE_ITEM: 'shopify/item/hide-item',
    HIDE_MULTIPLE_ITEM: 'shopify/item/hide-multiple-items',
    UNHIDE_FOLDER: 'shopify/item/unhide-folder',
    UNHIDE_ITEM: 'shopify/item/unhide-item',
    UNHIDE_MULTIPLE_ITEM: 'shopify/item/unhide-items',
    UPDATE_ITEM: 'shopify/item/update',
    CREATE_ORDER: 'shopify/bpxOrders/store',
    GET_CURRENCY : 'shopify/bpxOrders/currencies',
    CREATE_PAYMENT : 'shopify/bpxOrderPayments/store',
    GET_PAYMENTMETHOD :'shopify/payment-methods',
    GET_PLAN : 'shopify/bpx-plans',
    FORGOT_PASSWORD : '/forgot-password',
    RESET_PASSWORD : '/reset-password',
    SUPPLIER: 'supplier',
    ALL_SUPPLIERS: '/shopify/supplier',
    CREATE_SUPPLIER: '/supplier/create',
    PRODUCT_VENDORS_STATE: '/shopify/product-vendors-state',
    CREATE_SUPPLIER_CONTACT: '/supplier/contacts/create',
    UPDATE_SUPPLIER_CONTACT: '/supplier/contacts/update',
    SUPPLIER_CONTACT: '/supplier/contacts',
    LINK_UNLINK_VENDOR: '/shopify/link-unlink-vendor',
    CHECK_DOMAIN_VERIFICATION_STATUS:'/shopify/domain/check-domain-verification-status',
    MAIL_SETTINGS:'/shopify/domain/mail-settings',
    ROLES: '/roles',
    BIS_SETTINS: '/shopify/variants/bis-settings',
    GET_BIS_SETTINGS: '/shopify/variants/get-bis-settings',
    AUTO_UPDATE: '/shopify/variants/auto-update',
    REQUEST_STOCKS: '/shopify/variants/request-stocks',
    GET_STOCK_LISTS: '/shopify/variants',
    BIS_ACTIVITY_LOG: '/shopify/variants/bis-activity-logs',
    SAVE_SETTING: '/shopify/site-settings/save',
    SITE_SETTING: '/shopify/site-settings',
    USER: '/user',
    GET_ENTITY: '/user/details',
    GET_ALL_SEASON: '/shopify/season',
    CREATE_SEASON: '/season/create',
    LOGOUT:'/auth/login',
    GET_ITEM_TYPE: '/shopify/item-types',
    GET_SHOPIFY_PRODUCT: '/get-product-by-vendor',
    UPLOAD_TO_SHOPIFY: '/upload-to-shopify',
    SHOPIFY: 'shopify',
    PRODUCTS: 'products',
    GET_SHOPIFY_PRODUCTS: '/shopify/products',
    GET_SHOPIFY_SUPPLIERS: '/shopify/products-vendors',
    GET_PRODUCT_TAGS: '/shopify/products-tags',
    EXPORT_PRODUCT_LIST: '/shopify/export-products',
    GET_SHOPIFY_SUPPLIER_BY_ID: '/shopify/product-variants',
    ADD_BARCODE : 'shopify/site-settings/save',
    GET_ALL_ORDER : '/shopify/bpxOrders/all',
    GET_BARCODE_DATA:'/shopify/variants/barcode-settings',
    GET_TRACKING : '/shopify/productTracker',
    GET_TAG:'/shopify/item/tags',
    GET_STATS:'/shopify/products-calculation',
    GET_CUSTOMERS:'api/customers',
    ADD_TAGS_TO_PRODUCT : '/shopify/products/add_tags',
    GET_SIZING : '/shopify/sizing/all',
    GET_SIZING_HEADERS : '/shopify/sizing/sizing-headers',
    SALES_CHANNEL : '/shopify/salesChannel',
    COLLECTION_LIST : '/shopify/products/collections-list'
};
