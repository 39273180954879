import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AddImagesComponent implements OnInit {
  @Input() imagePath: any = '';
    @Input() defaultImage: any = this.utilService.defaultImage;
    @Input() brokenImage: any = this.utilService.brokenImage;
    @Input() classes: any = '';
    @Output() imageClicked = new EventEmitter<string>();

  constructor(public utilService: UtilService) { }

  ngOnInit(): void {
  }

  onClickImage(): void {
    this.imageClicked.emit();
  }
}
