import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { EndPoints } from '../shared/endpoint';
import { Router } from '@angular/router';

@Injectable()
export class AppConfigService {
  userDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lodader = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private router: Router) {}

  async getLoggedUser(): Promise<any> {
    const token = localStorage.getItem(environment.authTokenKey);
    if (token) {
      return this.http
        .get<any>(environment.apiBaseUrl + EndPoints.GET_ENTITY)
        .toPromise()
        .then((res) => {
          this.userDetails$.next(res.data);
          return res.data;
          
        })
        .catch((error) => {
          localStorage.clear();
          if (error.status == 401) {
            this.router.navigate(['/auth/login']);
          }
        });
    }
  }
}
