import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    user: any = JSON.parse(localStorage.getItem(environment.bpxUserDetailsKey) || '{}');
    defaultImage = '/assets/images/loading-round.gif';
    brokenImage = '/assets/images/broken-image.jpg';

    constructor(
        public toastr: ToastrService
    ) {

    }

    get getDOSpacePath() {
        this.user = JSON.parse(localStorage.getItem(environment.bpxUserDetailsKey) || '{}');
        let DOSpacePath;
        if (this.user && this.user.bucket_host) {
            DOSpacePath = this.user.bucket_host
        }
        return DOSpacePath;
    };

    get getTimeNow(): string {
        return moment().format('YYYY-MM-DD HH:mm:ss');
    }

    showSuccessToast(message: string) {
        this.toastr.show(
            '<div class="min-w-350px"><span class="alert-icon ni ni-bell-55" data-notify="icon"></span>' +
            '<span class="alert-title" data-notify="title">Success</span></div> ' +
            '<div class="mt-3"><span data-notify="message">' + `${message}` + '</span></div>',
            '',
            {
                timeOut: 2000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: 'alert-title',
                positionClass: 'toast-bottom-right',
                toastClass:
                    'ngx-toastr alert alert-dismissible alert-success alert-notify'
            }
        );
    }

    showSuccessToastclipBoard(message: string) {
        this.toastr.show(
            '<div class="min-w-350px"><span class="alert-icon ni ni-bell-55" data-notify="icon"></span>' +
            '<span class="alert-title" data-notify="title">Copied to clipboard</span></div> ',
            '',
            {
                timeOut: 2000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: 'alert-title',
                positionClass: 'toast-bottom-right',
                toastClass:
                    'ngx-toastr alert alert-dismissible alert-success alert-notify'
            }
        );
    }

    showErrorToast(message: string) {
        this.toastr.show(
            '<div class="min-w-350px"><span class="alert-icon ni ni-bell-55" data-notify="icon"></span> ' +
            '<span class="alert-title" data-notify="title">Error</span></div>' +
            '<div class="mt-3"> <span data-notify="message">' + `${message}` + '</span></div>',
            '',
            {
                timeOut: 2000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: 'alert-title',
                positionClass: 'toast-bottom-right',
                toastClass:
                    'ngx-toastr alert alert-dismissible alert-danger alert-notify'
            }
        );
    }

    displayErrorMessage(errors:any) {
        let errorMessage = '';
        if (errors.error.errors) {
            Object.keys(errors.error.errors).forEach((e) => {
                errors.error.errors[e].forEach((err:any, index:any) => {
                    if (errorMessage === '') {
                        errorMessage = err;
                    } else {
                        errorMessage = errorMessage + '<br/>' + err;
                    }
                });
            });
            this.showErrorToast(errorMessage);
        } else if (errors.error.message) {
            this.showErrorToast(errors.error.message);
        } else {
            this.showErrorToast('Something went wrong, Operation not performed');
        }
    }

    getImagePath(currentItem: any, currentItemImgIndex: any): string {
        let path;
        this.user = JSON.parse(localStorage.getItem(environment.bpxUserDetailsKey) || '{}');
        if(currentItem?.raw_data && typeof currentItem.raw_data[currentItemImgIndex] === 'string' ) {
                path = this.user.cdn_compress_host + currentItem.raw_data[currentItemImgIndex];
        }else if(currentItem?.raw_data && typeof currentItem.raw_data[currentItemImgIndex] === 'object'){
            path = this.user.cdn_compress_host + currentItem.raw_data[currentItemImgIndex].path;
        } else {
            path = currentItem?.file_url;
        }
        return path;
    }
}
