import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { getCurrentUserData } from '../../shared/current-user';

@Injectable({ providedIn: 'root' })
export class BuyerGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    try {
      const loggedUser = getCurrentUserData();
      if (loggedUser && loggedUser.business_type_id === 1) return true;
    } catch (err) {
      this.router.navigateByUrl('/catalogs');
      return false;
    }
    this.router.navigateByUrl('/catalogs');
    return false;
  }
}
