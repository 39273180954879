// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverBaseUrl: 'https://api.bpx22.buyerpix.com',
  apiBaseUrl: 'https://api.bpx22.buyerpix.com/api',
  authTokenKey: 'bpx22-web-auth-token',
  bpxUserDetailsKey: 'bpxUserDetails',
};
