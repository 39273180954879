import Swal from 'sweetalert2';

export function exportErrorMessage(message: string) {
    Swal.fire({
        type: 'error',
        title: '<h2 style="color: #33325D">' + message + '</h2>',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        position: 'top-right',
        backdrop: false,
        customClass: {
            container: 'sw-container-class',
        }
    });
}


export function afterLoading(messege:string, icon: any = null, type:any = 'info') {
    return Swal.fire({
        titleText: messege,
        width:360,
        allowOutsideClick: false,
        type,
        heightAuto:true,
        allowEscapeKey: true,
        allowEnterKey: false,
        imageUrl: icon,
        imageHeight:70,
        onOpen: () => {
            Swal.showLoading()
        }
    })
}

export function askForDeleteConfirmation(title: string, text: string, confirmButtonText = 'Yes, Delete',
                                         cancelButtonText = 'No, Cancel'): Promise<any> {
    return Swal.fire({
        title,
        text,
        type: 'question',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-secondary',
        confirmButtonText,
        cancelButtonText,
        buttonsStyling: false,
        reverseButtons: true,
        preConfirm: () => {
            setTimeout(() => {
                Swal.showLoading();
                let temp = document.querySelector("div.swal2-show.swal2-loading");
                let loader = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading");
                temp?.classList.add('my-swal');
                loader?.classList.add("load");
                let loaderBtn = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading.load button");
                loaderBtn?.remove();
            }, 200);
        }
    })
}

export function askForLogoutConfirmation(title: string,  confirmButtonText = 'Yes, Logout',
                                         cancelButtonText = 'No, Cancel'): Promise<any> {
    return Swal.fire({
        title,
        html:"Are you sure you wish to <br/> logout of your account?",
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-secondary',
        confirmButtonText,
        cancelButtonText,
        buttonsStyling: false,
        reverseButtons: true,
    })
}

export function askForDeleteConfirmationNormal(title: string, html:string,  confirmButtonText = 'Yes, Delete',
                                         cancelButtonText = 'No, Cancel'): Promise<any> {
    return Swal.fire({
        title,
        html,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-secondary',
        confirmButtonText,
        cancelButtonText,
        buttonsStyling: false,
        reverseButtons: true,
        preConfirm: () => {
            setTimeout(() => {
                Swal.showLoading();
                let temp = document.querySelector("div.swal2-show.swal2-loading");
                let loader = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading");
                temp?.classList.add('my-swal');
                loader?.classList.add("load");
                let loaderBtn = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading.load button");
                loaderBtn?.remove();
            }, 200);
        }
    })
}

export function askForHideConfirmationNormal(title: string, text:String,  confirmButtonText = 'Yes, Hide',
                                         cancelButtonText = 'No, Cancel'): Promise<any> {
    return Swal.fire({
        title,
        html:text,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-secondary',
        confirmButtonText,
        cancelButtonText,
        buttonsStyling: false,
        reverseButtons: true,
        preConfirm: () => {
            setTimeout(() => {
                Swal.showLoading();
                let temp = document.querySelector("div.swal2-show.swal2-loading");
                let loader = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading");
                temp?.classList.add('my-swal');
                loader?.classList.add("load");
                let loaderBtn = document.querySelector("div.swal2-show.swal2-loading div.swal2-actions.swal2-loading.load button");
                loaderBtn?.remove();              

            }, 200);
        },
    })
}