import { environment } from '../../environments/environment';

export const getCurrentUserData = () => {
  const data = localStorage.getItem(environment.bpxUserDetailsKey);
  return data ? JSON.parse(data) : undefined;
};

export const setCurrentUserData = (data: any) => {
  localStorage.setItem(environment.bpxUserDetailsKey, JSON.stringify(data));
};
