import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  sideBarClick = localStorage.getItem('sidebar_minimize') || false;

  constructor() {}

  public sideBar$: BehaviorSubject<any> = new BehaviorSubject(
    this.sideBarClick
  );

  getSideBar(): Observable<any> {
    return this.sideBar$.asObservable();
  }

  setSideBar() {
    let sideBarClick = JSON.parse(
      localStorage.getItem('sidebar_minimize') || '{}'
    );
    sideBarClick = !sideBarClick;
    localStorage.setItem('sidebar_minimize', sideBarClick.toString());
    this.sideBar$.next(sideBarClick);
  }
}
